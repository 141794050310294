import React, { Component } from 'react'
import { Grid, Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';
import '../App.css'
import '../stylesheets/MuiTypography.css'


class ComponentStatusDisplay extends Component {

    renderStatusIcon(status) {
        switch (status) {
            case "UP": return <CheckCircleIcon className="icon upIcon"/>
            case "WARN": return <ErrorIcon className="icon warnIcon"/>
            case "DOWN": return <CancelIcon className="icon downIcon"/>
            default: return <HelpIcon className="icon unknownIcon"/>
        }
    }

    render() {
        const component = this.props.component;
        return (
            <React.Fragment>
                <Box className="component">
                <Grid container style={{padding: '5px 20px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Grid item>
                        <Typography variant="h5">{ component.displayName }</Typography>
                    </Grid>
                    <Grid item>
                        { this.renderStatusIcon(component.status.state)}
                    </Grid>
                </Grid>
                </Box>
            </React.Fragment>
        )
    }
}

export default ComponentStatusDisplay
