import React, { Component } from 'react'
import { Typography, Grid, Box } from '@material-ui/core';
import ComponentStatusDisplay from './ComponentStatusDisplay';
import '../stylesheets/MuiTypography.css'

/**
 * This component is made to display posts/notifications about ongoing issues for the Communicate Status Dashboard
 */
class IssuePost extends Component {

    /**
     * Helper method to render the component statuses inside the issue post component
     */
    renderComponentDisplays() {
        var components = [];
        for (var alias of this.props.body.components) {
            components.push(<Grid item key={alias} xs={12} style={{marginBottom: '5px'}}><ComponentStatusDisplay component={ this.props.getComponentFromAlias(alias)} getTimeString={timestamp => this.props.getTimeString(timestamp)}/></Grid>)
        }
        return components;
    }

    /**
     * Implementation of React render method. Renders the description, time and summary based on given props. Relevant component statuses are dynamically rendered by the renderComponentDisplays() method.
     */
    render() {
        return (
            <React.Fragment>
                <Box className="component">
                    <Grid container style={{padding: '10px'}}>
                        <Grid item xs={12} style={{marginBottom: '5px'}}>
                            <Typography variant="h6">{ this.props.body.summary }</Typography>
                            <Typography variant="subtitle1">Posted: { this.props.getTimeString(this.props.body.time) }</Typography>
                            <Typography variant="body1">{ this.props.body.description }</Typography>
                        </Grid>
                        { this.renderComponentDisplays() }
                    </Grid>
                </Box>
            </React.Fragment>
        )
    }
}

export default IssuePost