import React, { Component } from 'react'
import { Typography, Box } from '@material-ui/core';
import '../stylesheets/MuiTypography.css'

class NoticePost extends Component {

    render() {
        return (
            <React.Fragment>
                <Box className="component" style={{ padding: '10px' }}>
                    <Typography variant="h6">{ this.props.body.summary }</Typography>
                    <Typography variant="subtitle1">Posted: { this.props.getTimeString(this.props.body.time) }</Typography>
                    <Typography variant="body1">{ this.props.body.description }</Typography>
                </Box>
            </React.Fragment>
        )
    }
}

export default NoticePost
