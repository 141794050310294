import React from 'react';
import './App.css';
import { Typography, Grid, Divider, Link, CircularProgress } from '@material-ui/core';
import ComponentStatusDisplay from './components/ComponentStatusDisplay';
import IssuePost from './components/IssuePost';
import NoticePost from './components/NoticePost';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';
import logo from './Communicate_TEAL.png';

/**
 * Main class that makes requests for status information, and renders all components of the dashboard. Components are populated with the retreived status info.
 */
class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      numColumns: ( window.matchMedia("(min-width: 1024px)").matches ? this.componentGridColumns : 1 ),
      alignLegend: ( window.matchMedia("(min-width: 1024px)").matches ? "right" : "left"),
      isLoaded: false,
      statusInfo: undefined
    }
  }

  /*
   * Declaring variables for URL to get status information, timer used for polling and time between requests
   */
  statusInfoPath = process.env.REACT_APP_STATUS_INFO_URL
  timer = null
  showIssues = (process.env.REACT_APP_SHOW_ISSUES || "true").toLowerCase() === "true"
  showNotices = (process.env.REACT_APP_SHOW_NOTICES || "true").toLowerCase() === "true"
  pollingCycleTime = process.env.REACT_APP_STATUS_POLL_RATE || 120000
  componentGridColumns = process.env.REACT_APP_COMPONENT_GRID_COLUMNS || 2


  sortOptions = {
    'status' : {
      'sortAttribute' : (process.env.REACT_APP_STATUS_SORT_ATTRIBUTE || ''),
      'sortReverse' : (process.env.REACT_APP_STATUS_SORT_REVERSE || 'false' ).toLowerCase() === 'true'
    },
    'issue' : {
      'sortAttribute' : (process.env.REACT_APP_ISSUE_SORT_ATTRIBUTE || ''),
      'sortReverse' : (process.env.REACT_APP_ISSUE_SORT_REVERSE || 'false' ).toLowerCase() === 'true'
    },
    'notice' : {
      'sortAttribute' : (process.env.REACT_APP_NOTICE_SORT_ATTRIBUTE || ''),
      'sortReverse' : (process.env.REACT_APP_NOTICE_SORT_REVERSE || 'false' ).toLowerCase() === 'true'
    }
  }

  /**
   * Helper method to make a GET request to the endpoint containing the component status information.
   */
  getStatusInfo() {
    return fetch(this.statusInfoPath, { method: 'HEAD' }).then(response => {
      if (response.headers.get('Last-Modified') !== this.state.infoLastModified) {
        this.setState({infoLastModified: response.headers.get('Last-Modified')})
        return fetch(this.statusInfoPath)
          .then(response => response.json())
          .then(
            (result) => { this.setState({ isLoaded: true, statusInfo: result }) },
            (error) => { this.setState({ isLoaded: true }) }
          )
      }
    }, () => {
        this.setState({ isLoaded: true })
    })
  }

  sortObjects(objectArray, attribute, reverse) {
    if (!attribute) {
      return;
    }

    objectArray.sort((x, y) => {
      const x_a = x[attribute];
      const y_a = y[attribute];
      return (x_a < y_a ? -1 : (x_a > y_a ? 1 : 0));
    })
    if (reverse === true) {
      objectArray.reverse();
    }
  }

  /**
   * Helper method to dynamically render multiple components in a table.
   * @param {string} title The title to be displayed by the table
   * @param {number} numColumns The number of columns in the table
   * @param {string} componentType String that describes the type of components that the table will contain. Either 'status', 'issue' or 'notice'
   * @param {object[]} jsonObjectList The JSON list object containing info to be displayed. Each component is populated with data from a corresponding object.
   */
  renderTable(title, numColumns, componentType, jsonObjectList) {
    let table = [];
    this.sortObjects(jsonObjectList, this.sortOptions[componentType].sortAttribute, this.sortOptions[componentType].sortReverse)
    for (var jsonObject of jsonObjectList) {
      table.push(
        <Grid item key={ jsonObject.alias || jsonObject.summary } xs={12/numColumns}>
          {{
            'status': <ComponentStatusDisplay component={jsonObject} getTimeString={timestamp => this.getTimeString(timestamp)}/>,
            'issue': <IssuePost body={jsonObject} getComponentFromAlias={alias => this.getComponentFromAlias(alias)} getTimeString={timestamp => this.getTimeString(timestamp)}/>,
            'notice': <NoticePost body={jsonObject} getTimeString={timestamp => this.getTimeString(timestamp)}/>
          }[componentType]}
        </Grid>
      )
    }
    return (
      <div className="pane">
          <Typography variant="h4">{ title }</Typography>
          {{
            'status': <Typography variant="body1" align={this.state.alignLegend}>Operational{<CheckCircleIcon  className="legendIcon upIcon"/>} &emsp; Disruption{<ErrorIcon className="legendIcon warnIcon"/>} &emsp; Outage{<CancelIcon  className="legendIcon downIcon"/>} &emsp; Unknown{<HelpIcon  className="legendIcon unknownIcon"/>}</Typography>,
            'issue': <Typography variant="body1" align="right"><Link href="https://supporttracker.orionhealth.com/" target="_blank" color="inherit">Report an issue</Link></Typography>
          }[componentType]}
          { table.length > 0 ?  <Grid container  className="tableContent" spacing={1}>{ table }</Grid> : <Typography variant="subtitle1" align="center">No {componentType}s to report</Typography>}
          {{
          'status': <Typography variant="subtitle1" style={{ marginTop: '-20px'}}>Last Checked: { this.getTimeString(this.state.statusInfo.published)}</Typography>
          }[componentType]}
          { table.length === 0 ? <Divider/> : null }
      </div>
    );
  }

  /**
   * Returns the object from the components list that has the alias given by the parameter
   */
  getComponentFromAlias = alias => {
    return this.state.statusInfo.components.find(component => {
      return component.alias === alias
    })
  }

  /**
   * Takes a timestamp as input and returns a formatted string conveying the time and date based on the user's locale. Will accept any date-time format that is compatible with JavaScript's Date object
   */
  getTimeString = timestamp => {
    const date = new Date(timestamp);
    if (isNaN(date)) {
      return timestamp
    } else {
      return date.toLocaleDateString('en-US') + " at " + date.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
    }
  }

  /**
   * Implementation of React hook method. Adds a listener to track the width of the window
   */
  componentDidMount() {
    if (!this.state.isLoaded) {
      this.getStatusInfo().then(() => {
        if (this.state.statusInfo !== undefined) {
          this.timer = setInterval( () => this.getStatusInfo(), this.pollingCycleTime);
        }
      });
    }

    const handler = e => {
      this.setState({mediaQuerySet: true})
      if (e.matches) {
        this.setState({numColumns: this.componentGridColumns, alignLegend: "right"})
      } else {
        this.setState({numColumns: 1, alignLegend: "left"})
      }
    }
    window.matchMedia("(min-width: 1024px)").addListener(handler)

  }

  /**
   * Implementation of componentWillUnmount hook method. Stops and dereferences the timer.
   */
  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = null
  }

  /**
   * Implementation of React render method. Checks to see if status info is properly loaded, and if it is, renders component statuses, then issues, then notices.
   */
  render() {
    if (!this.state.isLoaded) {
      return (
      <div className="window" style={{ textAlign: 'center'}}>
        <img src={logo} alt="Communicate Logo" className="logo"/>
        <CircularProgress size={100} style={{color: '#004866'}}/>
      </div>);
    }
    else if (this.state.statusInfo === undefined) {
      return (
        <div className="window" style={{ textAlign: 'center'}}>
          <img src={logo} alt="Communicate Logo" className="logo"/>
          <div className="window">Status information could not be loaded, please try again later.</div>
          <div>If this problem persists, please contact your local Communicate administrator.</div>
        </div>
      );
    }
    else {
      return (
        <div className="window">
          <img src={logo} alt="Communicate Logo" className="logo"/>
          { this.renderTable("System Status", this.state.numColumns, 'status', this.state.statusInfo.components) }
          { this.showIssues && this.renderTable("Ongoing Issues", 1, 'issue', this.state.statusInfo.issues) }
          { this.showNotices && this.renderTable("Notices", 1, 'notice', this.state.statusInfo.notices) }
        </div>
      );
    }
  }
}

export default App;
